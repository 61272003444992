import * as base from "~/config/textMessages/base.json";
import * as custom from "~/config/textMessages/custom.json";

export default defineI18nConfig(() => ({
  locale: "custom",
  fallbackLocale: "base",
  legacy: false,
  messages: {
    base,
    custom,
  },
}));
